import React from 'react'
import { Link } from 'react-router-dom'

import { Spinner } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'

import PowerRatio from '../../../shared/components/PowerRatio'
import SystemStatus from '../../../shared/components/SystemStatus'
import DateTime from '../../../shared/components/DateTime'
import PowerValue from '../../../shared/components/PowerValue'

const SystemTableRow = ({ columns, system, update }) => {
    const [oldSystem, setOldSystem] = React.useState(false)
    React.useEffect(() => {
        let mounted = true
        if (mounted) {
            setOldSystem(system)
        }
        return () => (mounted = false)
    }, [system])
    const TableCell = ({ column }) => {
        let content
        if (column.accessor === 'last_know_data') {
            content = <DateTime data={system.last_know_data} />
        } else if (column.accessor === 'description') {
            content = <Link to={`/system/${system.system}/detail`}>{system[column.accessor]}</Link>
        } else if (column.accessor === 'working_invertors_count') {
            content = system && <b>{system[column.accessor]}</b>
        } else if (column.accessor === 'power_chart') {
            content = <PowerRatio power={system.power} maxpower={system.maxpower} />
        } else if (column.accessor === 'power') {
            content = <PowerValue value={system.power} />
        } else if (column.accessor === 'error') {
            content =
                system.error !== undefined ? (
                    <SystemStatus error={system.error} />
                ) : (
                    <Spinner animation="border" role="status" variant="info">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                )
        } else {
            content = system[column.accessor]
        }
        return (
            <div>
                <b className="d-md-none">{column.Header}:&nbsp;</b>
                {content}
            </div>
        )
    }

    return (
        <CSSTransition in={oldSystem !== system} timeout={500} classNames="systemUpdate">
            <tr className={`align-items-center`}>
                {columns.map((column, index) => (
                    <td key={index}>
                        {column.length > 0 ? (
                            column.map((item) => <TableCell key={item.accessor} column={item} />)
                        ) : (
                            <TableCell key={column.accessor} column={column} />
                        )}
                    </td>
                ))}
            </tr>
        </CSSTransition>
    )
}
export default SystemTableRow
// kate: replace-tabs on; tab-width 4; show-tabs on;
