import React from 'react'
import Moment from 'react-moment'

import { Calendar } from '../../shared/components/Icons'

const Date = ({ data }) => {
    const dateTime =
        <div className="d-flex align-items-center">
            <Calendar style={{ color: 'grey', height: '1rem', marginRight: '.5rem' }} /> <Moment date={data} format={'D. M. YYYY'} />
        </div>

    return(
        dateTime
    )
}

export default Date

// kate: replace-tabs on; tab-width 4; show-tabs on;
