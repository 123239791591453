import React from 'react'
import { useIntl } from 'react-intl'

const InvertorError = ({ error, statusmessage }) => {
    const intl = useIntl()

    var message = ''
    switch (error) {
        case 0:
            message = intl.formatMessage({ id: 'errors.noError', defaultMessage: 'OK' })
            break

        case 1:
            message = intl.formatMessage({ id: 'errors.systemError', defaultMessage: 'Invertors with error' })
            break

        case 2:
            message = intl.formatMessage({
                id: 'errors.powerLow5Error',
                defaultMessage: 'Invertor reports 5 times power under norm',
            })
            break

        case 3:
            message = intl.formatMessage({
                id: 'errors.powerZero5Error',
                defaultMessage: 'Invertor reports 5 times zero power',
            })
            break

        case 4:
            message = intl.formatMessage({ id: 'errors.bayesError', defaultMessage: 'Statistical error in the system' })
            break

        default:
        case 5:
            message = intl.formatMessage({ id: 'errors.unknownError', defaultMessage: 'Unknown error' })
            break

        case 6:
            message = intl.formatMessage({
                id: 'errors.unexpectedLimitationError',
                defaultMessage: 'Unexpected power limitation',
            })
            break

        case 7:
            message = statusmessage
            break;
    } // switch

    return <>{message}</>
}

export default InvertorError
