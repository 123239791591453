import React from 'react'

import { useDataStream, useFetchRedux } from '../hooks'

import Header from './Header'
import Content from './Content'

export default function Page(props) {
    useFetchRedux('/api/v1/systems', 'systems')
    useFetchRedux('/api/v1/groups', 'groups')
    useFetchRedux('/api/v1/sensors', 'sensors')
    useDataStream('/api/v1/systems', 'system', 'systemsUpdated')

    // console.log(store.getState())
    return (
        <div>
            <Header lang={props.lang} />
            <Content />
        </div>
    ) // return
} // Page()

// kate: replace-tabs on; tab-width 4; show-tabs on;
