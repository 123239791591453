import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import store from '../redux/store'
import { Arrow } from './Icons'

export default function Sidebar(props) {
    const groups = store.getState().groups
    const systems = store.getState().systems

    const location = useLocation()

    return (
        <aside
            className={`col-md-3 col-lg-2 mr-0 pt-2 d-none d-md-block bg-light sidebar-sticky sidebar ${
                !props.collapseState ? 'sidebar--show' : 'sidebar--hide'
            }`}
            id="sidebar"
            style={{ top: 'auto' }}
        >
            {groups &&
                groups
                    .sort((a, b) => (a.description > b.description ? 1 : b.description > a.description ? -1 : 0))
                    .map((group) => {
                        const groupSystemArray = systems && systems.filter((system) => group.group === system.group)
                        return (
                            groupSystemArray &&
                            groupSystemArray.length > 0 && (
                                <div key={group.group}>
                                    <p className="mb-0 mt-1 font-weight-bold">{group.description}</p>
                                    <ul className="list-unstyled">
                                        {systems &&
                                            systems
                                                .sort((a, b) =>
                                                    a.description > b.description
                                                        ? 1
                                                        : b.description > a.description
                                                        ? -1
                                                        : 0
                                                )
                                                .map(
                                                    (system) =>
                                                        group.group === system.group && (
                                                            <li
                                                                key={system.system}
                                                                className={
                                                                    location.pathname.split('/')[2] === system.system
                                                                        ? 'font-weight-bold'
                                                                        : ''
                                                                }
                                                            >
                                                                <Link to={`/system/${system.system}/detail`}>
                                                                    <Arrow direction="right" /> {system.description}
                                                                </Link>
                                                            </li>
                                                        )
                                                )}
                                    </ul>
                                </div>
                            )
                        )
                    })}
        </aside>
    )
} // Sidebar()

// kate: replace-tabs on; tab-width 4; show-tabs on;
