import React from 'react'
import { Table } from 'react-bootstrap'
import { TransitionGroup } from 'react-transition-group'

import { useSortableData } from '../../../shared/hooks'

import { Arrow } from '../../../shared/components/Icons'
import SystemTableRow from './TableRow'

const SystemsTable = ({ data, columns, update }) => {
    const { items, requestSort, sortConfig } = useSortableData(
        data.sort((a, b) => (a.description > b.description ? 1 : b.description > a.description ? -1 : 0))
    )

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return
        }
        return sortConfig.key === name ? sortConfig.direction : undefined
    }
    const TableHeader = ({ column }) => (
        <div onClick={() => requestSort(column.accessor)} className={getClassNamesFor(column.accessor)}>
            {column.Header}
            <Arrow direction="up" />
            <Arrow direction="down" />
        </div>
    )

    return (
        <Table striped responsive hover className="systemView mb-5">
            <thead className="thead-dark">
                <tr>
                    {columns.map((column, index) => (
                        <th key={index}>
                            {column.length > 0 ? (
                                column.map((item) => <TableHeader column={item} key={item.accessor} />)
                            ) : (
                                <TableHeader column={column} />
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <TransitionGroup component={'tbody'}>
                {Object.values(items).map((system) => (
                    <SystemTableRow key={system.system} system={system} columns={columns} update={update} />
                ))}
            </TransitionGroup>
        </Table>
    )
}

export default SystemsTable

// kate: replace-tabs on; tab-width 4; show-tabs on;
