import React from 'react'
import NumberFormat from 'react-number-format'

const PowerValue = ({ value }) => {
    const power = parseFloat((Math.round(value) / 1000).toFixed(2))
    const powerValue = value && (
        <NumberFormat
            value={power}
            thousandSeparator={' '}
            decimalSeparator={','}
            decimalScale={2}
            suffix="&nbsp;kW"
            displayType={'text'}
        />
    )

    return powerValue ? powerValue : '0 kW'
}

export default PowerValue

// kate: replace-tabs on; tab-width 4; show-tabs on;
