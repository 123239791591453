import React, { useState } from 'react'
import { Alert, Button, Container, Form, ListGroup, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

function LoginAlert(props) {
    if (props.error.length > 0) {
        return (
            <ListGroup.Item className="pb-0">
                <Alert variant="danger">{props.error}</Alert>
            </ListGroup.Item>
        )
    } // if

    return ''
} // LoginAlert

export default function LoginView(props) {
    const [error, setError] = useState('')
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const intl = useIntl()

    const validateForm = () => {
        return login.length > 0 && password.length > 0
    } // validateForm()

    const handleSubmit = (event) => {
        const loginUrl = process.env.REACT_APP_API + '/api/v1/authenticate?user=' + login + '&password=' + password

        let headers = new Headers()

        event.preventDefault()
        fetch(loginUrl, {
            //mode: 'cors',
            cache: 'no-cache',
            headers: headers,
        })
            .then((response) => {
                if (response.ok) return response.json()
            })
            .then((data) => {
                // console.log(data);
                Object.assign(data, { password: password })

                if (!['service master', 'operator', 'supervisor'].includes(data.role)) {
                    console.log('Wrong role')
                    setError(intl.formatMessage({ id: 'login.failed', defaultMessage: 'Login failed.' }))

                    window.location = '/'

                    return
                }

                window.localStorage.setItem('identity', JSON.stringify(data))

                const identity = JSON.parse(window.localStorage.getItem('identity'))

                if (
                    identity !== null &&
                    (identity.language !== undefined || identity.language !== null || identity.language !== '')
                ) {
                    window.location = '/' + identity.language + '/system'
                } else {
                    window.location = '/en/system'
                } // if

                return
            })
            .catch((error) => {
                console.log(error)
                setError(intl.formatMessage({ id: 'login.failed', defaultMessage: 'Login failed.' }))

                //window.location = '/';

                return
            })
    } // handleSubmit()

    return (
        <Container id="loginView">
            <Row>
                <form className="col-md-4 col-sm-12 list-group">
                    <ListGroup.Item className="p-3">
                        <h1 className="h3 font-weight-normal text-center">
                            <FormattedMessage id="login.title" defaultMessage="Fotomon Control Login" />
                        </h1>
                    </ListGroup.Item>

                    <LoginAlert error={error} />

                    <ListGroup.Item className="p-3">
                        <Form.Group>
                            <Form.Label className="sr-only">
                                <FormattedMessage id="login.user" defaultMessage="User name" />:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="login"
                                className="text-center"
                                placeholder={intl.formatMessage({ id: 'login.user', defaultMessage: 'User name' })}
                                autoFocus
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                                autoComplete="username"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="sr-only">
                                <FormattedMessage id="login.password" defaultMessage="Password" />:
                            </Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                className="text-center"
                                placeholder={intl.formatMessage({ id: 'login.password', defaultMessage: 'Password' })}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="current-password"
                            />
                        </Form.Group>

                        <Form.Group className="mb-0">
                            <Button
                                block
                                type="submit"
                                disabled={!validateForm()}
                                variant="primary"
                                onClick={handleSubmit}
                            >
                                <FormattedMessage id="login.submit" defaultMessage="Login" />
                            </Button>
                        </Form.Group>
                    </ListGroup.Item>
                </form>
            </Row>
        </Container>
    ) // return
} // LoginView()

// kate: replace-tabs on; tab-width 4; show-tabs on;
