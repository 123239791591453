import React from 'react'
import { Dropdown } from 'react-bootstrap'


const LangSwitcher = ({ lang }) => {
    return (
        <Dropdown className="lang-switcher ml-md-3 " alignRight>
            <Dropdown.Toggle className="text-light px-0" variant="transparent">
            {lang}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item className="p-2" href="/en">
                   <span className="lang-icon">en</span> english
                </Dropdown.Item>
                <Dropdown.Item className="p-2" href="/cs">
                   <span className="lang-icon">cs</span> česky
                </Dropdown.Item>
                </Dropdown.Menu>
        </Dropdown>
    )
/** Odloženo pro použití do okamžiku, kdy bude skutečně existovat ukrajinský překlad */
/*
                <Dropdown.Item className="p-2" href="/ua">
                    <span className="lang-icon">ua</span> Український
                </Dropdown.Item>
*/
}

export default LangSwitcher

// kate: replace-tabs on; tab-width 4; show-tabs on;
