import React, { useEffect, useState } from 'react'
import { useEventStreamSingle } from '../../shared/hooks'
import { useIntl } from 'react-intl'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

HighchartsMore(Highcharts)

const InvertorPowerGraph = ({ systemID, maxPower, invertorID }) => {
    const intl = useIntl()

    const [invertors] = useEventStreamSingle(`/api/v1/nninvertor/${invertorID}`)

    //useDataStream(`/api/v1/nninvertor`, 'system', 'graphInvertors', false, invertorID)

    //const invertors = store.getState().graphInvertors

    const [data, setData] = useState(false)

    useEffect(() => {
        console.log(invertors)

        const newArray = {
            items: [],
            x: [],
        }

        if (invertors) {
            invertors.forEach((invertor, index) => {
                //if (index > 20 && index < 70) {
                if (invertor.group_data.quartile2 > 0) {
                    const q1 = invertor.group_data.quartile1
                    const q2 = invertor.group_data.quartile2
                    const q3 = invertor.group_data.quartile3
                    const iqr = 1.5 * (q3 - q1)
                    const ni = invertor.invertor_data.count
                    const ip = ni !== 0 ? invertor.invertor_data.sum_power / ni : 0
                    const lowerfence = q2 - iqr < 0 ? 0 : q2 - iqr
                    const upperfence = Math.min(q2 + iqr, invertor.group_data.max)
                    const time = new Date(invertor.time)
                    const item = {
                        x: time.getTime(),
                        low: Math.round(lowerfence * maxPower),
                        q1: Math.round(q1 * maxPower),
                        median: Math.round(q2 * maxPower),
                        q3: Math.round(q3 * maxPower),
                        //x: Math.round(ip * maxPower),
                        high: Math.round(upperfence * maxPower),
                    }

                    newArray.items.push(item)
                    newArray.x.push([time.getTime(), Math.round(ip * maxPower)])
                }
            })
        }
        // console.log(newArray)

        setData(newArray)
    }, [invertors, maxPower])
    return data ? (
        Object.values(data.items).length > 0 ? (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    yAxis: {
                        title: {
                            text: intl.formatMessage({
                                id: 'invertorPowerGraph.yAxis',
                                defaultMessage: 'Power [W]',
                            }),
                        },
                    },
                    xAxis: {
                        title: {
                            text: intl.formatMessage({ id: 'invertorPowerGraph.xAxis', defaultMessage: 'Time [hour]' }),
                        },
                        type: 'datetime',
                    },
                    series: [
                        {
                            name: intl.formatMessage({
                                id: 'invertorPowerGraph.invertor',
                                defaultMessage: 'This invertor',
                            }),
                            data: data.x,
                            color: '#f24039',
                            type: 'spline',
                            zIndex: 1,
                        },
                        {
                            name: intl.formatMessage({
                                id: 'invertorPowerGraph.invertorGroupTitle',
                                defaultMessage: 'Invertors in system',
                            }),
                            data: data.items,
                            type: 'boxplot',
                        },
                    ],
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false,
                            },
                        },
                    },
                    tooltip: {
                        valueSuffix:
                            ' ' + intl.formatMessage({ id: 'invertorPowerGraph.tooltipUnit', defaultMessage: 'W' }),
                        valueDecimals: 0,
                        xDateFormat: '%d.%m.%Y %H:%M',
                        shared: true,
                    },
                    title: {
                        text: intl.formatMessage({
                            id: 'invertorPowerGraph.title',
                            defaultMessage: 'Today invertor power',
                        }),
                    },
                    credits: {
                        enabled: false,
                    },
                }}
            />
        ) : (
            'no usable data'
        )
    ) : (
        'loading'
    )
}

export default InvertorPowerGraph

// kate: replace-tabs on; tab-width 4; show-tabs on;
