import moment from 'moment'
import 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { Calendar } from '../../shared/components/Icons'
import getApiUrl from '../../shared/lib/getApiUrl'
import Datetime from 'react-datetime'

const identity = JSON.parse(window.localStorage.getItem('identity'))

export default function LimitationModal({
    show,
    onHide,
    powerOff,
    selectedSystems,
    fetchData,
    selectedSystem,
    editedLimitation,
    forecastCorrection,
}) {
    const nowDateTime =
        show && // IF MODAL SHOW
        moment().format('D.M.YYYY') +
            moment()
                .add(forecastCorrection && (moment().format('mm') > 5 ? 4 : 3), 'hours') // IF FORECAST CORRECTION - ADD HOURS
                .add(1, 'minute')
                .format(' HH:mm')
    const afterHourDateTime =
        show && // IF MODAL SHOW
        moment().format('D.M.YYYY') +
            moment()
                .add(forecastCorrection ? (moment().format('mm') > 5 ? 5 : 4) : 1, 'hours') // IF FORECAST CORRECTION - ADD HOURS
                .add(1, 'minute')
                .format(' HH:mm')

    const [start, setStart] = useState(nowDateTime)
    const [end, setEnd] = useState(afterHourDateTime)

    useEffect(() => {
        nowDateTime && setStart(nowDateTime)
    }, [nowDateTime])

    useEffect(() => {
        afterHourDateTime && setEnd(afterHourDateTime)
    }, [afterHourDateTime])
    const [percent, setPercent] = useState(100)
    const intl = useIntl()

    const languagePathPart = window.location.pathname.split('/')[1]
    const language = languagePathPart === 'en' ? 'cs' : languagePathPart
    moment.locale(language)

    const onPercentChange = (e) => {
        const value = e.target.value
        value <= 150 && setPercent(value)
    }

    const onDateChange = (e, setState) => {
        switch (typeof e) {
            case 'object':
                setState(e.format('D.M.YYYY') + e.format(' HH:mm'))
                break

            case 'string':
                setState(moment(e, 'D.M.YYYY HH:mm').format('D.M.YYYY') + moment(e, 'D.M.YYYY HH:mm').format(' HH:mm'))
                break

            default:
                setState(moment(nowDateTime).format('D.M.YYYY') + moment(nowDateTime).format(' HH:mm'))
                break
        }
    }
    const limitation = {
        // system: systemID,
        ...{
            user: identity.user,
            date_from: moment(start, 'D.M.YYYY HH:mm').format(),
            date_to: moment(end, 'D.M.YYYY HH:mm').format(),
        },
        ...(!powerOff && !forecastCorrection && { percent: parseFloat(percent) }),
        ...(forecastCorrection && { correction: parseFloat(percent) / 100 }),
        ...(editedLimitation &&
            (forecastCorrection ? { id: editedLimitation.id } : { limitation: editedLimitation.limitation })),
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        let startTimestamp = moment(limitation.date_from).unix()
        let endTimestamp = moment(limitation.date_to).unix()
        let currentTimestamp = moment().unix()
        if (!forecastCorrection && (startTimestamp <= currentTimestamp || endTimestamp <= currentTimestamp)) {
            alert(
                intl.formatMessage({
                    id: 'limitations.pastDate',
                    defaultMessage:
                        'Start or End date and time of the limitation cannot be in past (next minute at least)',
                })
            )
            return
        }
        if (!forecastCorrection && startTimestamp >= endTimestamp) {
            alert(
                intl.formatMessage({
                    id: 'limitations.startEndDateWrong',
                    defaultMessage: 'Start date and time cannot be same or after End date and time of the limitation',
                })
            )
            return
        }

        const setLimitation = async (selectedSystem) => {
            const res = await fetch(
                getApiUrl(
                    forecastCorrection
                        ? '/api/v1/forecast-correction/'
                        : powerOff
                        ? `/api/v1/poweroff/`
                        : `/api/v1/limitations/${editedLimitation?.limitation ?? ''}`
                ),
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...limitation, system: selectedSystem }),
                }
            )

            const data = await res.json()
            console.log('Success:', data)
        }
        if (selectedSystem) {
            await setLimitation(selectedSystem)
        } else if (selectedSystems) {
            for (const selectedSystem of selectedSystems) {
                await setLimitation(selectedSystem.systemId)
            }
        }

        fetchData()
        onHide()
    }

    const dateValid = (e) => e.isAfter(moment())

    useEffect(() => {
        if (editedLimitation) {
            setStart(
                moment(editedLimitation.date_from).format('D.M.YYYY') +
                    moment(editedLimitation.date_from).format(' HH:mm')
            )

            setEnd(
                moment(editedLimitation.date_to).format('D.M.YYYY') + moment(editedLimitation.date_to).format(' HH:mm')
            )
            setPercent(editedLimitation.percent)
        }
    }, [editedLimitation])
    return (
        <Modal centered show={show} onHide={onHide} className="modal--add-limitation">
            <Modal.Header closeButton>
                <Modal.Title className="h5">
                    <FormattedMessage
                        id={
                            editedLimitation
                                ? 'limitations.editlimitation'
                                : powerOff
                                ? 'powerOff.setup'
                                : 'limitations.setup'
                        }
                        defaultMessage={
                            editedLimitation
                                ? 'Edit power limitation'
                                : powerOff
                                ? 'Set up power turn off'
                                : 'Set power limitation'
                        }
                    />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={(e) => onSubmit(e)}>
                    <Form.Group as={Row}>
                        <Form.Label xs="12" md="3" className="text-right" column>
                            <FormattedMessage id="limitations.start" defaultMessage="From" />
                        </Form.Label>
                        <Col xs="12" md="auto">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <Calendar style={{ color: 'black', height: '1rem' }} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Datetime
                                    onChange={(e) => onDateChange(e, setStart)}
                                    value={start ? start : nowDateTime}
                                    locale={language}
                                    inputProps={{
                                        className: 'form-control text-right',
                                        disabled: editedLimitation
                                            ? moment().unix() > moment(editedLimitation.date_from).unix()
                                            : false,
                                    }}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>{moment().tz(moment.tz.guess()).format('z')}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label xs="12" md="3" className="text-right" column>
                            <FormattedMessage id="limitations.end" defaultMessage="To" />
                        </Form.Label>
                        <Col xs="12" md="auto">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <Calendar style={{ color: 'black', height: '1rem' }} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Datetime
                                    isValidDate={(e) => dateValid(e)}
                                    onChange={(e) => onDateChange(e, setEnd)}
                                    value={end ? end : afterHourDateTime}
                                    locale={language}
                                    inputProps={{
                                        className: 'form-control text-right',
                                    }}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>{moment().tz(moment.tz.guess()).format('z')}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    {!powerOff && (
                        <Form.Group as={Row} className="align-items-center">
                            <Form.Label xs="12" md="3" className="text-right" column>
                                <FormattedMessage id="limitations.limitto" defaultMessage="Limit to" />
                            </Form.Label>
                            <Col xs="12" md="auto">
                                <InputGroup>
                                    <Form.Control
                                        type="number"
                                        value={percent || ''}
                                        onChange={(e) => onPercentChange(e)}
                                        min={0}
                                        max={150}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            %{' '}
                                            <FormattedMessage
                                                id="limitations.nominalpower"
                                                defaultMessage="of nominmal power"
                                            />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    )}
                    <div className="text-right">
                        <Button variant="outline-secondary" className="mr-2" onClick={onHide}>
                            <FormattedMessage id="close" defaultMessage="Close" />
                        </Button>
                        <Button type="submit">
                            <FormattedMessage id="limitations.submit" defaultMessage="Set" />
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
