import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './App'

import * as serviceWorker from './serviceWorker'
import { IntlProvider } from 'react-intl'
import cs from './locales/cs.json'
import en from './locales/en.json'

import 'react-datetime/css/react-datetime.css'
import './custom.scss'
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import store from './shared/redux/store'
import './index.css'

const messages = {
    cs: cs,
    en: en,
}
let lang
if (window.location.pathname.split('/')[1] === '') {
    let navLanguage = navigator.language
    lang = navLanguage.includes('-') ? navLanguage.split('-')[0] : 'en'
} else {
    var pathPart = window.location.pathname.split('/')[1]
    lang = pathPart.length === 2 ? pathPart : 'en'
}
const language = lang

ReactDOM.render(
    <React.StrictMode>
        <IntlProvider locale={language} defaultLocale="en" messages={messages[language]}>
            <Provider store={store}>
                <BrowserRouter basename={`/${language}`}>
                    <App lang={language} />
                </BrowserRouter>
            </Provider>
        </IntlProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
/*
        <Provider store={store}>
        </Provider>
*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// kate: replace-tabs on; tab-width 4; show-tabs on;
