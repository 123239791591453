import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Table } from 'react-bootstrap'

import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/cs'
import 'moment/locale/uk'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEdit, faCog } from '@fortawesome/free-solid-svg-icons'

import DateTimeComponent from '../../../shared/components/DateTime'
import store from '../../../shared/redux/store'
import LimitationModal from '../../limitations/LimitationModal'
import usePowerOffs from '../../../shared/hooks/usePowerOff'
const Restrictions = ({ fetchRestrictions, restrictions, forecastCorrection, powerOff, systemID }) => {
    const system = store.getState()[`system-${systemID}`]
    const { deletePowerOff } = usePowerOffs()
    const intl = useIntl()
    const [modal, setModal] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [editedRestriction, setEditedRestriction] = useState(false)
    const identity = JSON.parse(window.localStorage.getItem('identity'))
    const language = window.location.pathname.split('/')[1]
    moment.locale(language)
    const onDelete = (e, restriction) => {
        e.preventDefault()
        if (powerOff) {
            deletePowerOff(restriction)
        } else {
            const doDelete = async () => {
                await fetch(
                    process.env.REACT_APP_API +
                        `/api/v1/${forecastCorrection ? 'forecast-correction' : 'limitations'}/` +
                        restriction +
                        '?user=' +
                        identity.login +
                        '&password=' +
                        identity.password,
                    {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                    .then((response) => response.json())
                    .then((data) => {
                        console.log('Success:', data)
                        fetchRestrictions()
                    })
                    .catch((error) => {
                        console.error('Error:', error)
                    })
            }
            doDelete()
        }
    }

    const toggleModal = () => setModal((prev) => !prev)
    const toggleModalEdit = (restriction) => {
        // console.log(restriction)
        setEditedRestriction(restriction)
        setModalEdit(!modalEdit)
    }
    return (
        <React.Fragment>
            {system && (
                <LimitationModal
                    show={modal}
                    onHide={() => setModal(false)}
                    selectedSystem={system.system}
                    fetchData={fetchRestrictions}
                    forecastCorrection={forecastCorrection}
                />
            )}
            {system && (
                <LimitationModal
                    show={modalEdit}
                    onHide={() => setModalEdit(false)}
                    selectedSystem={system.system}
                    editedLimitation={editedRestriction}
                    fetchData={fetchRestrictions}
                    forecastCorrection={forecastCorrection}
                />
            )}

            {!powerOff && (
                <Button onClick={toggleModal}>
                    <FontAwesomeIcon
                        icon={faCog}
                        title={intl.formatMessage({ id: `limitations.setup`, defaultMessage: 'Set power Limitation' })}
                        className="mr-2"
                    />
                    {forecastCorrection ? (
                        <FormattedMessage id={`forecast-correction.setup`} defaultMessage="Set forecast correction" />
                    ) : (
                        <FormattedMessage id={`limitations.setup`} defaultMessage="Set power limitation" />
                    )}
                </Button>
            )}

            {restrictions && restrictions.length > 0 && (
                <Table className="mt-4" responsive>
                    <thead className="thead-dark">
                        <tr>
                            {!powerOff && (
                                <th>
                                    <FormattedMessage id="limitations.limit" defaultMessage="Limit" />
                                </th>
                            )}
                            <th>
                                <FormattedMessage id="limitations.start" defaultMessage="From" />
                            </th>
                            <th>
                                <FormattedMessage id="limitations.end" defaultMessage="To" />
                            </th>
                            <th>
                                <FormattedMessage id="limitations.user" defaultMessage="User" />
                            </th>
                            <th>
                                <FormattedMessage id="limitations.entered" defaultMessage="Entered" />
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {restrictions
                            .sort((a, b) => (a.date_from < b.date_from ? 1 : b.date_from < a.date_from ? -1 : 0))
                            .map((restriction) => (
                                <tr
                                    key={
                                        forecastCorrection
                                            ? restriction.id
                                            : powerOff
                                            ? restriction.powerOff
                                            : restriction.limitation
                                    }
                                    className={`${
                                        moment().unix() >= moment(restriction.date_from).unix() &&
                                        moment().unix() <= moment(restriction.date_to).unix()
                                            ? 'table-success'
                                            : ''
                                    }`}
                                >
                                    {!powerOff && <td>{Math.round(restriction.percent)}%</td>}
                                    <td>
                                        <DateTimeComponent inline data={restriction.date_from} timeFormat={'LT z'} />
                                    </td>
                                    <td>
                                        <DateTimeComponent inline data={restriction.date_to} timeFormat={'LT z'} />
                                    </td>
                                    <td>{restriction.name}</td>
                                    <td>
                                        <DateTimeComponent inline data={restriction.createdate} timeFormat={'LT z'} />
                                    </td>
                                    <td className="text-center">
                                        {!powerOff && (
                                            <Button
                                                size="sm"
                                                variant="outline-primary"
                                                onClick={() => toggleModalEdit(restriction)}
                                                className={`pr-1 mr-2 ${
                                                    !forecastCorrection &&
                                                    moment().unix() > moment(restriction.date_to).unix()
                                                        ? 'invisible'
                                                        : ''
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    title={intl.formatMessage({
                                                        id: 'limitations.edit',
                                                        defaultMessage: 'edit',
                                                    })}
                                                    size="lg"
                                                />
                                            </Button>
                                        )}
                                        {!powerOff && (
                                            <Button
                                                size="sm"
                                                variant="outline-danger"
                                                onClick={(e) =>
                                                    onDelete(
                                                        e,
                                                        forecastCorrection ? restriction.id : restriction.limitation
                                                    )
                                                }
                                                className={
                                                    !forecastCorrection &&
                                                    moment().unix() > moment(restriction.date_from).unix()
                                                        ? 'invisible'
                                                        : ''
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    title={intl.formatMessage({
                                                        id: 'limitations.remove',
                                                        defaultMessage: 'Remove',
                                                    })}
                                                    size="lg"
                                                />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    )
}

export default Restrictions

// kate: replace-tabs on; tab-width 4; show-tabs on;
