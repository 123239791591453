import React from 'react'

import { Sun, CardinalDirections } from '../../../shared/components/Icons'
const SunPosition = ({ invertor }) => {
    return (
        <div
            className="sun-position d-flex flex-wrap mt-4 mb-4 mx-auto position-relative"
            style={{ width: '10rem', height: '10rem', zIndex: 2 }}
        >
            <CardinalDirections
                style={{
                    width: '7rem',
                    top: 0,
                    opacity: '.15',
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
                className="position-absolute m-auto"
            />
            <span
                className="d-block position-absolute font-weight-bold text-center"
                style={{
                    transform: 'translateY(-50%)',
                    top: '50%',
                    left: '-3rem',
                    fontSize: '.7rem',
                }}
            >
                {Math.round(invertor.geo.sun.ss_az) + '°'}
                <br />
                {invertor.geo.sun.ss_tm.substring(0, 5)}
            </span>
            <span
                className="d-block position-absolute font-weight-bold text-center"
                style={{
                    transform: 'translateY(-50%)',
                    top: '50%',
                    right: '-3rem',
                    fontSize: '.7rem',
                }}
            >
                {Math.round(invertor.geo.sun.sr_az) + '°'}
                <br />
                {invertor.geo.sun.sr_tm.substring(0, 5)}
            </span>

            <div
                className="d-block font-weight-bold text-center text-primary"
                style={{
                    transform: `rotate(${((invertor.geo.az - 180) * Math.PI) / 180}deg) translateY(-50%)`,

                    position: 'absolute',
                    top: '50%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    left: '.45rem',
                    right: 0,
                    fontSize: '.7rem',
                }}
            >
                <div>
                    <span
                        className="bg-primary d-inline-block"
                        style={{ width: '.75rem', height: '1.35rem', borderRadius: '.15rem' }}
                    />
                    <span
                        className="bg-primary d-inline-block mx-1"
                        style={{ width: '.75rem', height: '1.35rem', borderRadius: '.15rem' }}
                    />
                    <span
                        className="bg-primary d-inline-block mr-1"
                        style={{ width: '.75rem', height: '1.35rem', borderRadius: '.15rem' }}
                    />
                    <span
                        className="bg-primary d-inline-block"
                        style={{ width: '.75rem', height: '1.35rem', borderRadius: '.15rem' }}
                    />
                </div>
                <span className="position-absolute" style={{ top: '-1rem', left: 0, right: 0 }}>
                    {Math.round(invertor.geo.sun.ang) + '°'}
                </span>
                <span className="position-absolute" style={{ bottom: '-1rem', left: 0, right: 0 }}>
                    {Math.round(invertor.geo.az) + '°'}
                </span>
            </div>
            <div
                style={{
                    position: 'absolute',
                }}
                className="w-100 h-100"
            >
                <div
                    style={{
                        position: 'absolute',
                        transition: 'transform .1s',
                        //border: '.25rem solid #f3f3f3',
                        border: '5px solid #ffc107',
                        // borderBottomLeftRadius: '1000rem' /* 100px of height + 10px of border */,
                        // borderBottomRightRadius: '1000rem' /* 100px of height + 10px of border */,

                        width: '100%',
                        height: '100%',
                        borderRadius: '1000rem',
                        bottom: 0,
                        '--sunPositionHeight': `${
                            125 - (Date.parse(`1 1 ${invertor.geo.sun.ss_tm}`) - 978300000000) / 1000000
                        }%`,
                        zIndex: -1,
                    }}
                    className="sun-position__circle"
                />
                <div
                    style={{
                        position: 'absolute',
                        transform: `rotate(${invertor.geo.sun.az - 45}deg) translate(-50%, -50%)`,
                        transition: 'transform .1s',
                        width: '82%',
                        height: '82%',
                        left: '50%',
                        top: '50%',
                        transformOrigin: 'top left',
                    }}
                >
                    <div className="sun-position__sun">
                        <Sun
                            className="text-warning ml-auto d-block position-relative"
                            style={{ width: '1.5rem', zIndex: 1 }}
                        />
                    </div>
                </div>
            </div>
            <div
                className="font-weight-bold position-absolute d-inline-block rounded text-white py-1 px-2"
                style={{
                    fontSize: '.7rem',
                    bottom: '-1.5rem',
                    left: '-3.25rem',
                    background: 'rgba(0,0,0,.5)',
                }}
            >
                <p className="m-0">AZ: {Math.round(invertor.geo.sun.az)}°</p>
                <p className="m-0">EL: {Math.round(invertor.geo.sun.el)}°</p>
            </div>
        </div>
    )
}

export default SunPosition
