import React from 'react'

import Page from './shared/components/Page'
import LoginView from './modules/login/LoginView'

import './App.css'

export default function App(props) {
    const identityItem = window.localStorage.getItem('identity')

    if (identityItem === null) {
        return <LoginView />
    } else {
        return <Page lang={props.lang} /> // return
    } // if
} // App()

// kate: replace-tabs on; tab-width 4; show-tabs on;
