import React, { useState, useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import moment from 'moment'
import 'moment-timezone'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

export default function Clock(props) {

    const [ date, setDate ] = useState(new Date());

    useEffect(() => {
        var timerId = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(timerId);
        }; // cleanup()
    }) // useEffect()

    function tick() {

        setDate(new Date());
    } // tick()

    return (
        <Navbar.Text className="ml-3">
            <span title={moment(date).tz(moment.tz.guess()).format('l LT:s z')}>
                <FontAwesomeIcon icon={faClock} />&nbsp;
                {moment(date).tz(moment.tz.guess()).format('LT:ss z')}
            </span>
        </Navbar.Text>
    );
} // Clock()

// kate: replace-tabs on; tab-width 4; show-tabs on;
