import { useEffect, useState } from 'react'
import { AuthenticationError } from '../errors'
import getApiUrl from '../lib/getApiUrl'

export default function useFetch({ url, enabled = true }) {
    const [data, setData] = useState(null)
    const [headers, setHeaders] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal
        const doFetch = async () => {
            setLoading(true)
            try {
                const res = await fetch(getApiUrl(url), { credentials: 'include' })
                const json = await res.json()
                const headersArray = {}
                res.headers.forEach((header, index) => (headersArray[index] = header))

                if (!res.ok) {
                    throw json.message
                } else if (!signal.aborted) {
                    setData(json)
                    setHeaders(headersArray)
                }
            } catch (e) {
                if (!signal.aborted) {
                    setError(e)
                }
                if (e instanceof AuthenticationError) {
                    window.localStorage.removeItem('identity')
                    window.location = '/'

                    return
                }
            }
        }
        enabled && doFetch()
        return () => {
            abortController.abort()
        }
    }, [url, enabled])
    return { data, headers, error, loading }
}
