import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Breadcrumbs(props) {

    const crumbs = props.crumbs;

    if (crumbs.length <= 1) {

        return null;
    } // if

    return (
        <Breadcrumb className="my-2">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            {crumbs.map(({ name, path }, key) =>
                key + 1 === crumbs.length
                    ? <Breadcrumb.Item key={key} active>{name}</Breadcrumb.Item>
                    : <Breadcrumb.Item key={key} linkAs={Link} linkProps={{ to: path }}>{name}</Breadcrumb.Item>
            )}
        </Breadcrumb>
    );
} // Breadcrumbs()

// kate: replace-tabs on; tab-width 4; show-tabs on;
