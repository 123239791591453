import { AuthenticationError } from '../errors'
import getApiUrl from './getApiUrl'

export default async function fetchData({ url, setData, setLoading }) {
    const abortController = new AbortController()
    const signal = abortController.signal
    try {
        setLoading(true)
        const res = await fetch(getApiUrl(url), { credentials: 'include' })

        const json = await res.json()
        const headersArray = []
        res.headers.forEach((header, index) => (headersArray[index] = header))

        if (!res.ok) {
            throw json.message
        } else if (!signal.aborted) {
            setData(json)
        }
    } catch (e) {
        if (!signal.aborted) {
            console.log(e)
        }
        if (e instanceof AuthenticationError) {
            window.localStorage.removeItem('identity')
            window.location = '/'

            return
        } //
    } finally {
        if (!signal.aborted) {
            setLoading(false)
        }
    }
}
