import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import getApiUrl from '../lib/getApiUrl'
import store from '../redux/store'
import useFetch from './useFetch'
// function throttle(func, timeFrame) {
//     var lastTime = 0
//     return function () {
//         var now = Date.now()
//         if (now - lastTime >= timeFrame) {
//             func()
//             lastTime = now
//         }
//     }
// }

export default function useDataStream({ url, eventUrl, identifier, type, enabled = true }) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [update, setUpdate] = useState(null)
    const [data, setData] = useState(null)
    const dispatch = useDispatch()
    const eventSource = useRef(null)

    const { data: initialData } = useFetch({ url, enabled })
    useEffect(() => {
        if (enabled) {
            eventSource.current = new EventSource(getApiUrl(eventUrl), {
                withCredentials: true,
            })
            return () => {
                eventSource.current.close()
            }
        }
    }, [eventUrl, enabled])

    // const updateData = (eventData) => {
    //     const parsedData = JSON.parse(eventData)

    //     Array.isArray(initialData)
    //         ? setUpdate(
    //               [...data].map((item) => {
    //                   if (item[identifier ?? 'id'] === parsedData[identifier ?? 'id']) {
    //                       return parsedData
    //                   }
    //                   return item
    //               })
    //           )
    //         : setUpdate({ ...data, ...parsedData })
    // }

    useEffect(() => {
        const getData = async () => {
            eventSource.current.onmessage = (e) => {
                setUpdate(JSON.parse(e.data))
            }

            eventSource.current.onerror = (e) => {
                console.log(e)
                eventSource.current.close()
            }
            return () => {
                eventSource.current.close()
            }
        }

        enabled && getData()
    }, [eventUrl, initialData, enabled])

    useEffect(() => {
        enabled &&
            initialData &&
            (Array.isArray(initialData)
                ? setData(
                      [...initialData].map((item) => {
                          if (update && item[identifier ?? 'id'] === update[identifier ?? 'id']) {
                              return { ...item, ...update }
                          }
                          return item
                      })
                  )
                : setData({ ...initialData, ...update }))
    }, [update, initialData])

    useEffect(() => {
        data && dispatch({ type: 'FETCH_DATA_SUCCESS', payload: { [type]: data } })
    }, [data])

    const storedData = store.getState()[type]

    return { data: storedData, error, loading }
}

// useEffect(() => {
//     const eventSource = new EventSource(getApiUrl(eventUrl), {
//         withCredentials: true,
//     })
//     const getData = async () => {
//         eventSource.onmessage = (e) => updateData(e.data)

//         eventSource.onerror = (e) => {
//             console.log(e)
//             eventSource.close()
//         }
//     }
//     const timer = setInterval(() => {
//         initialData && getData()
//     }, 300)
//     return () => {
//         eventSource.close()
//         clearInterval(timer)
//     }
// }, [eventUrl, initialData])
