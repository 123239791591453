import React from 'react'
import { useFetch } from '../../../shared/hooks'
import NumberFormat from 'react-number-format'
import { FormattedMessage } from 'react-intl'
import { Row, Col, Table } from 'react-bootstrap'

import ProductionValue from '../../../shared/components/ProductionValue'
import { Transformator } from '../../../shared/components/Icons'

const Meterings = ({ identity, systemID }) => {
    const [metering] = useFetch(`/api/v1/metering/${systemID}?user=${identity.login}&password=${identity.password}`)

    return (
        metering && (
            <Row>
                <Col lg="5">
                    <h6 className="mb-3 text-center">
                        <FormattedMessage id="highvoltage" defaultMessage="High voltage" />
                    </h6>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th className="text-right">ΔA-</th>
                                <th className="text-right">ΔA+</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metering.high_voltage.meterings.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ lineHeight: '1' }}>{item.description}</td>
                                    <td className="text-right font-weight-bold" title={item.aminus_date_today}>
                                        <ProductionValue value={item.aminus_delta_today} />
                                    </td>
                                    <td className="text-right" title={item.aplus_date_today}>
                                        <ProductionValue value={item.aplus_delta_today} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="font-weight-bold">
                                    <FormattedMessage id="sum" defaultMessage="Sum" />
                                </td>
                                <td
                                    className="font-weight-bold text-right"
                                    title={metering.high_voltage.sum.aminus_date_today}
                                >
                                    <ProductionValue value={metering.high_voltage.sum.aminus_delta_today} />
                                </td>
                                <td className="text-right" title={metering.high_voltage.sum.aplus_date_today}>
                                    <ProductionValue value={metering.high_voltage.sum.aplus_delta_today} />
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
                <Col lg="1" className="pb-4 pt-lg-5 p-lg-0 text-center ">
                    <h6 className="mb-0 mt-1 font-size">
                        <NumberFormat
                            value={`${
                                metering.high_voltage.sum.aminus_delta_today /
                                (metering.low_voltage.sum.aminus_delta_today / 100)
                            }`}
                            decimalScale={0}
                            suffix="&thinsp;%"
                            displayType={'text'}
                        />
                    </h6>
                    <Transformator className="d-none d-lg-block mx-auto" style={{ width: '100%' }} />
                </Col>
                <Col lg="6">
                    <h6 className="mb-3 text-center">
                        <FormattedMessage id="lowvoltage" defaultMessage="Low voltage" />
                    </h6>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th className="text-right">ΔA-</th>
                                <th className="text-right">ΔA+</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metering.low_voltage.meterings.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ lineHeight: '1' }}>{item.description}</td>
                                    <td className="text-right font-weight-bold" title={item.aminus_date_today}>
                                        <ProductionValue value={item.aminus_delta_today} />
                                    </td>
                                    <td className="text-right" title={item.aplus_date_today}>
                                        <ProductionValue value={item.aplus_delta_today} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <thead>
                            <tr>
                                <td className="font-weight-bold">
                                    <FormattedMessage id="sum" defaultMessage="Sum" />
                                </td>
                                <td
                                    className="font-weight-bold text-right"
                                    title={metering.low_voltage.sum.aminus_date_today}
                                >
                                    <ProductionValue value={metering.low_voltage.sum.aminus_delta_today} />
                                </td>
                                <td className="text-right" title={metering.low_voltage.sum.aplus_date_today}>
                                    <ProductionValue value={metering.low_voltage.sum.aplus_delta_today} />
                                </td>
                            </tr>
                        </thead>
                    </Table>
                </Col>
            </Row>
        )
    )
}

export default Meterings

// kate: replace-tabs on; tab-width 4; show-tabs on;
