import React from 'react'
import { Row, Col, Badge, Spinner } from 'react-bootstrap'

import { useEventStreamSingle } from '../hooks'

import { TemperatureOut, TemperaturePanel, Sun } from './Icons'

const Sensor = ({ sensor }) => {
    const [sensorData] = useEventStreamSingle(`/api/v1/sensors/${sensor.sensor}`)

    /*React.useEffect(() => {
        //console.log(sensorData)
        //console.log(Object.keys(sensorData).length > 0 && Object.values(sensorData))
        //console.log(Object.keys(sensorData).length > 0 && sensorData.values[sensorData.values.length - 1].value)
    }, [sensorData]) */
    const TypeComponent = ({ type }) => {
        switch (type) {
            case 1:
                return <TemperatureOut className="w-100 text-success" />
            case 2:
                return <Sun className="w-100 text-warning" />
            default:
                return <TemperaturePanel className="w-100 text-info" />
        }
    }
    return (
        <Col xs="12" md="6" lg="4" xl="3" className="mb-2">
            <div className="border rounded h-100 d-flex flex-column justify-content-center px-3 py-2">
                <Row>
                    <Col xs="8" className="d-flex align-items-center">
                        <small>
                            <b>{sensor.description}</b>
                        </small>
                    </Col>
                    <Col xs="1" className="d-flex align-items-center p-0">
                        {sensor.sensor_type && <TypeComponent type={sensor.sensor_type} />}
                    </Col>
                    <Col xs="3" className="d-flex align-items-center p-0 justify-content-center">
                        {Object.keys(sensorData).length > 0 ? (
                            <Badge variant="secondary" pill>
                                {sensorData.values[sensorData.values.length - 1].value.toFixed(2)}
                            </Badge>
                        ) : (
                            <Spinner animation="border" role="status" variant="info" size="sm">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        )}
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default Sensor

// kate: replace-tabs on; tab-width 4; show-tabs on;
