import React from 'react'
import { Sun } from '../../../shared/components/Icons'

export default function SunAngle({ invertor }) {
    const sunangle = invertor.geo.sun.el
    return (
        <div
            className="sun-angle d-flex flex-wrap  mt-5 mx-auto"
            style={{ width: '10rem', height: '10rem', position: 'relative' }}
        >
            <div className="font-weight-bold text-primary mt-2" style={{ fontSize: '.7rem' }}>
                <div
                    className="bg-primary"
                    style={{
                        height: '.35rem',
                        width: '2rem',
                        display: 'block',
                        borderRadius: '1rem',
                        transform: 'rotate(45deg)',

                        marginTop: '4.5rem',
                        position: 'absolute',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        left: 0,
                        right: '.6rem',
                    }}
                />
                <span className="position-absolute" style={{ top: '3rem', left: '3rem' }}>
                    {Math.round(invertor.geo.sun.ang) + '°'}
                </span>
                <span className="position-absolute" style={{ top: '5rem', right: '3rem' }}>
                    {Math.round(invertor.geo.el) + '°'}
                </span>
                <div
                    style={{
                        height: '.5rem',
                        background: 'grey',
                        width: '10rem',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        borderRadius: '1rem',
                        marginTop: '5.75rem',
                    }}
                />
                <svg width="500" height="500" style={{ position: 'absolute', top: 0, left: 0 }}>
                    <defs>
                        <linearGradient id="Gradient1">
                            <stop offset="0%" stopColor="white" />
                            <stop offset="30%" stopColor="#ffc107" />
                            <stop offset="60%" stopColor="#ffc107" />
                            <stop offset="100%" stopColor="white" />
                        </linearGradient>
                    </defs>
                    <line
                        x1="80"
                        y1="80"
                        x2={80 * Math.cos((-sunangle * Math.PI) / 180) + 93}
                        y2={80 * Math.sin((-sunangle * Math.PI) / 180) + 53}
                        //stroke="black"
                        stroke="url(#Gradient1)"
                    />
                </svg>
                <div
                    className="sun-angle__sun"
                    style={{
                        position: 'absolute',
                        transition: 'transform .1s',
                        top: 0,
                        left: 0,
                        transform: `translate(${80 * Math.cos((-sunangle * Math.PI) / 180) + 80}px, ${
                            80 * Math.sin((-sunangle * Math.PI) / 180) + 40
                        }px)`,
                        '--sunAngleRotation': `${Math.round(-sunangle - 20)}deg`,
                    }}
                >
                    <Sun className="text-warning ml-auto d-block" style={{ width: '1.5rem' }} />
                </div>
            </div>

            {/* <div
                style={{
                    position: 'absolute',
                    transform: `rotate(${Math.round(invertor.geo.sun.el * 10) / 10}deg)`,
                    transition: 'transform .1s',
                    borderRadius: '100%',
                }}
                className="w-100 h-100"
            >
                <div class="sun-angle__sun">
                    <Sun className="text-warning ml-auto d-block" style={{ width: '1.5rem' }} />
                </div>

                <div
                    className="font-weight-bold position-absolute d-inline-block"
                    style={{
                        transform: `rotate(-${Math.round(invertor.geo.sun.el * 10) / 10}deg)`,
                        fontSize: '.7rem',
                        top: '-1.5rem',
                        right: '-2rem',
                    }}
                >
                    EL: {Math.round(invertor.geo.sun.el * 10) / 10 + '°'}
                </div>
            </div> */}
        </div>
    )
}
