import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Spinner } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrochip } from '@fortawesome/free-solid-svg-icons'
import PowerRatio from '../../shared/components/PowerRatio'
import SystemStatus from '../../shared/components/SystemStatus'

const GroupSystem = ({ system, collapseState }) => {
    const [oldSystem, setOldSystem] = React.useState(false)
    React.useEffect(() => {
        setOldSystem(system)
    }, [system])
    return (
        <CSSTransition key={system.system} in={oldSystem !== system} timeout={500} classNames="groupUpdate">
            <Col
                xs="12"
                md={collapseState ? 4 : 6}
                lg={collapseState ? 3 : 4}
                xl={collapseState ? 2 : 3}
                className="mb-1"
            >
                <Row
                    className={`rounded m-0 py-3 align-items-center justify-content-between`}
                    style={{ background: 'rgb(241 241 241)' }}
                >
                    <Col xs="12">
                        <Link to={`/system/${system.system}/detail`}>
                            <h6>{system.description}</h6>
                        </Link>
                    </Col>
                    <Col xs="1" md="2">
                        {system.error !== undefined ? (
                            <SystemStatus error={system.error} />
                        ) : (
                            <Spinner animation="border" size="sm" role="status" variant="dark">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        )}
                    </Col>
                    <Col xs="5" className="pl-3 pr-1">
                        <small>
                            <FontAwesomeIcon className="text-secondary" icon={faMicrochip} />{' '}
                            <b>{system.working_invertors_count}</b>/{system.invertors_count}
                        </small>
                    </Col>
                    <Col xs="5">
                        <PowerRatio power={system.power} maxpower={system.maxpower} bgColor="white" />
                    </Col>
                </Row>
            </Col>
        </CSSTransition>
    )
}

export default GroupSystem

// kate: replace-tabs on; tab-width 4; show-tabs on;
