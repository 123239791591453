import React from 'react';

export default function NotFoundView(props) {

    return (
        <div>
            <h1>Not Found</h1>
            <p>We are sorry, but page <code>{window.location.pathname}</code> you requested doesn't exists.</p>
            <p>Please check URL address. If you get this page by clicking on link, please report this as error.</p>
        </div>
    );
} // NotFoundView()

// kate: replace-tabs on; tab-width 4; show-tabs on;
