import React from 'react'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWind, faTachometerAlt, faCompass } from '@fortawesome/free-solid-svg-icons'

const WeatherBlock = ({ weather, title, box }) => {
    const intl = useIntl()
    const existingSrc = () => {
        let src
        try {
            src = require(`../img/weather/${weather.weather}.png`).default
            // do stuff
        } catch {
            src = require(`../img/weather/unknown.png`).default
        }
        return src
    }

    return (
        <div className={`rounded p-2 bg-light d-flex align-items-center mb-2  ${box ? 'd-lg-block mb-lg-0' : ''}`}>
            {title && (
                <div className="mr-3" style={{ minWidth: '4rem' }}>
                    <small>
                        <b>{title}</b>
                    </small>
                    <hr className={`d-none my-2 ${box ? 'd-lg-block' : ''}`} />
                </div>
            )}
            <div className="d-flex align-items-center flex-wrap flex-grow-1 justify-content-between">
                <div className="mx-auto">
                    <img
                        src={existingSrc()}
                        title={intl.formatMessage({ id: `weather.${weather.weather ? weather.weather : 'unknown'}` })}
                        alt={weather.weather ? weather.weather : 'unknown'}
                    />
                </div>
                <div className="pl-2 mx-auto">
                    <b className="d-block text-right">{weather.temperature}&#176;C</b>
                </div>
                {weather.wind_velocity && (
                    <div className="pl-2 pl-lg-0 pl-xl-2 d-flex align-items-center text-center mx-auto">
                        <small>
                            <FontAwesomeIcon className="text-dark mr-2" icon={faWind} size="lg" />
                            <FontAwesomeIcon className="text-dark mr-1" icon={faTachometerAlt} />
                            <b className="mr-2">{weather.wind_velocity.toFixed(1)} m/s</b>
                            <FontAwesomeIcon className="text-dark mr-1" icon={faCompass} />
                            <b>{weather.wind_direction}</b>
                        </small>
                    </div>
                )}
            </div>
        </div>
    )
}

export default WeatherBlock

// kate: replace-tabs on; tab-width 4; show-tabs on;
