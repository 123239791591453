import { useEffect, useRef, useReducer, useState, useMemo } from 'react'

import { AuthenticationError } from './errors'
import { useDispatch } from 'react-redux'

const identity = JSON.parse(window.localStorage.getItem('identity'))
export const useSafeState = (initialValue) => {
    const isMountedRef = useRef(true)
    const [currentValue, setCurrentValue] = useState(initialValue)
    useEffect(() => {
        return () => {
            isMountedRef.current = false
        }
    }, [isMountedRef])
    const setSafeState = (value) => {
        if (isMountedRef && isMountedRef.current) {
            setCurrentValue(value)
        }
    }
    return [currentValue, setSafeState]
}
export const useFetch = (url) => {
    const [response, setResponse] = useState(null)
    const [headers, setHeaders] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal
        const doFetch = async () => {
            setLoading(true)
            try {
                const res = await fetch(
                    process.env.REACT_APP_API + url + '?user=' + identity.login + '&password=' + identity.password,
                    { credentials: 'include' }
                )

                const json = await res.json()
                const headersArray = {}
                res.headers.forEach((header, index) => (headersArray[index] = header))

                if (!res.ok) {
                    throw json.message
                } else if (!signal.aborted) {
                    setResponse(json)
                    setHeaders(headersArray)
                }
            } catch (e) {
                if (!signal.aborted) {
                    setError(e)
                }
                if (e instanceof AuthenticationError) {
                    window.localStorage.removeItem('identity')
                    window.location = '/'

                    return
                } //
            } finally {
                if (!signal.aborted) {
                    setLoading(false)
                }
            }
        }
        doFetch()
        return () => {
            abortController.abort()
        }
    }, [url])
    return [response, headers, error, loading]
}

export const useFetchRedux = (url, type) => {
    const [data, setData] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        const abortController = new AbortController()
        const doFetch = async () => {
            try {
                const res = await fetch(
                    process.env.REACT_APP_API + url + '?user=' + identity.login + '&password=' + identity.password,
                    { credentials: 'include' }
                )
                const json = await res.json()
                type === 'system' || type === 'sensors'
                    ? setData(json)
                    : setData(json.filter((item) => item.show_on_panel))
            } catch (e) {
                console.log(e)
            }
        }
        doFetch()
        return () => {
            abortController.abort()
        }
    }, [url, type])

    return dispatch({ type: 'FETCH_DATA_SUCCESS', payload: { [type]: data } })
}
/*
function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
*/

export const useEventStream = (url, id, suffix) => {
    const [update, setUpdate] = useState([])
    const eventSource = useRef(false)
    const eventData = useRef([])
    //const [updatedSystem, setUpdatedSystem] = useState(false)

    useEffect(() => {
        eventSource.current = new EventSource(
            process.env.REACT_APP_API + url + '?user=' + identity.login + '&password=' + identity.password,
            {
                withCredentials: true,
            }
        )
        return () => {
            eventSource.current.close()
        }
    }, [url])
    useEffect(() => {
        const timer = setInterval(() => {
            setUpdate(eventData.current)
        }, 500)
        return () => {
            clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        const onUpdate = (updateData) => {
            eventData.current.forEach((item, index) => {
                if (item[id] === updateData[id]) {
                    eventData.current[index] = updateData
                    //setUpdatedSystem(updateData.system)
                }
            })
            eventData.current = !eventData.current.some((item) => item && item[id] === updateData[id])
                ? [...eventData.current, updateData]
                : [...eventData.current]
        }
        eventSource.current.onmessage = (e) => {
            onUpdate(JSON.parse(e.data))
        }
        eventSource.current.onerror = (e) => {
            console.log(e)
        }
        return () => {
            eventSource.current.close()
        }
    }, [id])

    return [update]
}

export const useEventStreamSingle = (url) => {
    const [update, setUpdate] = useState([])
    const eventSource = useRef(false)
    useEffect(() => {
        const abortController = new AbortController()
        const doFetch = async () => {
            try {
                eventSource.current = new EventSource(
                    process.env.REACT_APP_API +
                        url +
                        '/events' +
                        '?user=' +
                        identity.login +
                        '&password=' +
                        identity.password,
                    {
                        withCredentials: true,
                    }
                )
                eventSource.current.onmessage = (e) => {
                    setUpdate(JSON.parse(e.data))
                }
            } catch (e) {
                console.log(e)
            }
        }
        doFetch()
        return () => {
            abortController.abort()
            eventSource.current.close()
        }
    }, [url])

    return [update]
}

export const useDataStream = (url, id, type, suffix, invertorID) => {
    const finalUrl = url + (invertorID ? '/' + invertorID : '') + (suffix && !invertorID ? suffix : '')
    const eventsUrl = url + (invertorID ? '/' + invertorID : '') + '/events' + (suffix && !invertorID ? suffix : '')

    const [systems] = useFetch(finalUrl)
    const [update] = useEventStream(eventsUrl, id)
    const [data, setData] = useState(systems || false)
    const dispatch = useDispatch()
    useEffect(() => {
        if (update && systems) {
            let merged = []
            for (let i = 0; i < systems.length; i++) {
                merged.push({
                    ...systems[i],
                    ...update.find((item) => item[id] === systems[i][id]),
                })
            }
            setData(merged)
        }
        return () => setData(false)
    }, [update, systems, id])

    return dispatch({ type: 'FETCH_DATA_SUCCESS', payload: { [type]: data } })
}

// export const useFetchInvertors = (url, eventsUrl) => {
//     const id = 'invertor'
//     const [systems] = useFetch(url)
//     const [update] = useEventStream(eventsUrl, id)
//     const [data, setData] = useState(systems || false)
//     const dispatch = useDispatch()
//     useEffect(() => {
//         console.log(update)
//         if (update && systems) {
//             let merged = []

//             for (let i = 0; i < systems.length; i++) {
//                 merged.push({
//                     ...systems[i],
//                     ...update.find((item) => item[id] === systems[i][id]),
//                 })
//             }
//             setData(merged)
//         }
//         return () => setData(false)
//     }, [update, systems, id])
//     return dispatch({ type: 'FETCH_DATA_SUCCESS', payload: { invertors: data } })
// }

export const useDataStreamSingle = (url, type) => {
    const [system] = useFetch(url)
    const [update] = useEventStreamSingle(url)
    const [data, setData] = useState(system || false)

    const dispatch = useDispatch()
    useEffect(() => {
        if (update && system) {
            setData({ ...system, ...update })
        }
        return () => {
            setData(false)
        }
    }, [update, system, url])

    return dispatch({ type: 'FETCH_DATA_SUCCESS', payload: { [type]: data } })
}

export const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config)

    const sortedItems = useMemo(() => {
        let sortableItems = [...items]
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (sortConfig.key === 'power_chart') {
                } else {
                }
                const from =
                    sortConfig.key === 'power_chart'
                        ? a.power > 0 && Math.round(a.power / (a.maxpower / 100))
                        : a[sortConfig.key] === undefined
                        ? a[sortConfig.key]
                        : a[sortConfig.key]
                const to =
                    sortConfig.key === 'power_chart'
                        ? b.power > 0 && Math.round(b.power / (b.maxpower / 100))
                        : b[sortConfig.key] === undefined
                        ? b[sortConfig.key]
                        : b[sortConfig.key]
                if (from < to) {
                    return sortConfig.direction === 'ascending' ? -1 : 1
                }
                if (from > to) {
                    return sortConfig.direction === 'ascending' ? 1 : -1
                }
                return 0
            })
        }
        return sortableItems
    }, [items, sortConfig])

    const requestSort = (key) => {
        let direction = 'ascending'
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending'
        }
        setSortConfig({ key, direction })
    }

    return { items: sortedItems, requestSort, sortConfig }
}

export const useFetchOld = (url, eventStream, viewType) => {
    const [, setError] = useState('')
    const cache = useRef({})
    const finalUrl = process.env.REACT_APP_API + url
    const initialState = {
        status: 'idle',
        error: null,
        data: [],
    }
    const [update, setUpdate] = useState(false)
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'FETCHING':
                return { ...initialState, status: 'fetching' }
            case 'FETCHED':
                return { ...initialState, status: 'fetched', data: action.payload, update: action.update }
            case 'FETCH_ERROR':
                return { ...initialState, status: 'error', error: action.payload }
            default:
                return state
        }
    }, initialState)

    useEffect(() => {
        let cancelRequest = false
        if (!url) return
        const fetchData = async () => {
            dispatch({ type: 'FETCHING' })
            if (cache.current[finalUrl]) {
                const data = cache.current[finalUrl]

                dispatch({ type: 'FETCHED', payload: data, update: update })
            } else {
                try {
                    const response = await fetch(finalUrl)
                    const data = await response.json()
                    cache.current[finalUrl] = data

                    if (eventStream) {
                        let eventSource = new EventSource(process.env.REACT_APP_API + eventStream)
                        eventSource.onmessage = (e) => setUpdate(JSON.parse(e.data))
                    }
                    if (cancelRequest) return
                    dispatch({ type: 'FETCHED', payload: data, update: update })
                } catch (error) {
                    if (cancelRequest) return
                    dispatch({ type: 'FETCH_ERROR', payload: error.message })
                    if (error instanceof AuthenticationError) {
                        window.localStorage.removeItem('identity')

                        setError(error.toString())

                        window.location = '/'

                        return
                    } //
                    setError(error.toString())
                }
            }
        }

        fetchData()

        return function cleanup() {
            cancelRequest = true
        }
    }, [url, eventStream, finalUrl, update])

    useEffect(() => {
        //if (eventStream) {
        //viewType === 'detail' ?
        //state.data.update = update && update
        //:
        //state.data.forEach(item => { if (update && item.system === update.system) { item.update = update } })
        //}
    }, [update, state.data, viewType])

    return state
}

// kate: replace-tabs on; tab-width 4; show-tab: on;
