import React from 'react';
import { Redirect } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default function UserInfo(props) {

    const handleLogout = (event) => {
        window.localStorage.clear();

        return <Redirect to="/login" />;
    } // handleLogout()

    const identityItem = window.localStorage.getItem('identity');

    if (identityItem === null) {
        return <Redirect to="/login" />
    } // if

    const identity = JSON.parse(identityItem);

    return (
        <Navbar.Text>
            <FontAwesomeIcon icon={faUserCircle} />&nbsp;
            <span title={identity.login}>{identity.name}</span>,&nbsp;
            {identity.role_description}&nbsp;
            <a href="/" title="Logout" onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} title="Logout" /></a>
        </Navbar.Text>
    ); // return
} // UserInfo()

// kate: replace-tabs on; tab-width 4; show-tabs on;
