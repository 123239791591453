import React from 'react'
import NumberFormat from 'react-number-format'

const ProductionValue = ({ value }) => {

    const production = parseFloat(Math.round(value).toFixed(2))
    const productionValue = production !== null && <NumberFormat value={production} thousandSeparator={' '} decimalSeparator={','} decimalScale={2} suffix='&nbsp;kWh' displayType={'text'} />

    return(
        productionValue ? productionValue : ''
    )
}

export default ProductionValue

// kate: replace-tabs on; tab-width 4; show-tabs on;
