import React from 'react'

import { FormattedMessage } from 'react-intl'
import store from '../../shared/redux/store'
import GroupsGroup from './Group'

export default function GroupView(props) {
    const groups = store.getState().groups
    const systemsUpdated = store.getState().systemsUpdated
    return (
        <div className="groupView">
            <h1 className="mb-4">
                <FormattedMessage id="groups.title" defaultMessage="Group views" />{' '}
            </h1>
            {groups &&
                groups.map((group) => {
                    const groupSystemArray =
                        systemsUpdated && systemsUpdated.filter((system) => group.group === system.group)
                    return (
                        groupSystemArray &&
                        groupSystemArray.length > 0 && (
                            <GroupsGroup
                                key={group.group}
                                group={group}
                                groupSystemArray={groupSystemArray}
                                collapseState={props.collapseState}
                            />
                        )
                    )
                })}
        </div>
    )
} // GroupView

// kate: replace-tabs on; tab-width 4; show-tabs on;
