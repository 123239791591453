import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'react-bootstrap'
import NumberFormat from 'react-number-format'

import InvertorError from '../../../shared/components/InvertorError'
import InvertorStatistics from '../../../shared/components/InvertorStatistics'
import DateTime from '../../../shared/components/DateTime'
import PowerValue from '../../../shared/components/PowerValue'
import PowerRatio from '../../../shared/components/PowerRatio'
import { Link } from 'react-router-dom'
import useDataStream from '../../../shared/hooks/useDataStream'

const Invertors = ({ activeTab, systemID }) => {
    const { data: invertors } = useDataStream({
        url: `/api/v1/invertors?system=${systemID}`,
        eventUrl: `/api/v1/invertors/events?system=${systemID}`,
        identifier: 'invertor',
        type: `invertors-${systemID}`,
        enabled: activeTab === 'invertors',
    })

    return activeTab === 'invertors' ? (
        <Table striped responsive hover className="systemView mb-5">
            <thead className="thead-dark">
                <tr>
                    <th>
                        <FormattedMessage id="invertor" defaultMessage="Invertor" />
                    </th>
                    <th>
                        <FormattedMessage id="status" defaultMessage="Status" />
                    </th>
                    <th className="text-right">
                        <FormattedMessage id="power" defaultMessage="Power" />
                    </th>
                    <th className="text-center">
                        <FormattedMessage id="powerratio" defaultMessage="Power Ratio" />
                    </th>
                    <th className="text-center">
                        <FormattedMessage id="statistics" defaultMessage="Statistics withing a group" />
                    </th>
                    <th>
                        <FormattedMessage id="lastupdate" defaultMessage="Last Known Data" />
                    </th>
                    <th>
                        <FormattedMessage id="error" defaultMessage="Error" />
                    </th>
                    <th>
                        <FormattedMessage id="probability" defaultMessage="Probability" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {invertors ? (
                    invertors
                        .sort((a, b) =>
                            a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, {
                                numeric: true,
                                ignorePunctuation: true,
                            })
                        )
                        .map((invertor, index) => (
                            <tr key={index} className={invertor.error !== 0 ? 'alert-danger' : ''}>
                                <td>
                                    <Link to={`/system/${systemID}/detail/invertor/${invertor.invertor}`}>
                                        {invertor.name}
                                    </Link>
                                </td>
                                <td>{invertor.status}</td>
                                <td className="text-right">
                                    <PowerValue value={invertor.power} />
                                </td>
                                <td className="text-center">
                                    <PowerRatio power={invertor.power} maxpower={invertor.maxpower} />
                                </td>
                                <td className="text-center">
                                    <InvertorStatistics invertor={invertor} />
                                </td>
                                <td>
                                    <DateTime data={invertor.time} inline={true} />
                                </td>
                                <td>
                                    <InvertorError error={invertor.error} statusmessage={invertor.status_message2} />
                                </td>
                                <td>
                                    <NumberFormat
                                        value={invertor.probability}
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                </td>
                            </tr>
                        ))
                ) : (
                    <tr>
                        <td>loading</td>
                    </tr>
                )}
            </tbody>
        </Table>
    ) : (
        'loading'
    )
}

export default Invertors

// kate: replace-tabs on; tab-width 4; show-tabs on;
