import GroupView from '../modules/group/GroupView'
import SystemView from '../modules/system/SystemView'
import SystemDetailView from '../modules/system/SystemDetailView'
import InvertorDetailView from '../modules/invertor/InvertorDetailView'
import Limitations from '../modules/limitations'

const routes = [
    {
        path: '/group',
        name: 'Groups',
        Component: GroupView,
    },
    {
        path: '/system',
        name: 'Systems',
        Component: SystemView,
    },
    {
        path: '/system/:system/detail',
        name: 'System Detail',
        Component: SystemDetailView,
    },
    {
        path: '/system/:system/detail/invertor/:invertor',
        name: 'Invertor Detail',
        Component: InvertorDetailView,
    },
    {
        path: '/limitations',
        name: 'Limitations',
        Component: Limitations,
    },
]

export default routes

// kate: replace-tabs on; tab-width 4; show-tabs on;
