import React, { useEffect, useRef } from 'react'
import L from 'leaflet'
import { Map as LeafletMap, LayersControl, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import store from '../../../shared/redux/store'

const Map = ({ activeTab }) => {
    const system = store.getState().system
    const position = system && [system.latitude, system.longitude]
    const { BaseLayer } = LayersControl
    const markerIcon = L.icon({
        iconRetinaUrl: iconRetinaUrl,
        iconUrl: iconUrl,
        shadowUrl: shadowUrl,
    })
    const mapRef = useRef(null)

    useEffect(() => {
        mapRef.current.leafletElement.invalidateSize()
    }, [activeTab])

    return (
        <div>
            <LeafletMap ref={mapRef} center={position} zoom={14} style={{ minHeight: '62vh', width: '100%' }}>
                <LayersControl position="bottomleft">
                    <BaseLayer checked name="Základní">
                        <TileLayer
                            attribution='© <a href="https://www.seznam.cz">Seznam.cz, a.s.</a>, © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, © NASA'
                            url="//mapserver.mapy.cz/turist-m/{z}-{x}-{y}"
                        />
                    </BaseLayer>
                    <BaseLayer name="Letecká">
                        <TileLayer
                            attribution='© <a href="https://www.seznam.cz">Seznam.cz, a.s.</a>, © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, © NASA'
                            url="//mapserver.mapy.cz/bing/{z}-{x}-{y}"
                        />
                    </BaseLayer>
                </LayersControl>
                <Marker position={position} icon={markerIcon}>
                    <Popup>{system && system.description}</Popup>
                </Marker>
            </LeafletMap>
        </div>
    )
}

export default Map

// kate: replace-tabs on; tab-width 4; show-tabs on;
