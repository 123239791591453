import React from 'react'
import { Row } from 'react-bootstrap'

import GroupSensor from '../../shared/components/Sensor'

const GroupSensors = ({ sensors }) => {
    return (
        <Row>
            {sensors.map((sensor) => (
                <GroupSensor key={sensor.sensor} sensor={sensor} />
            ))}
        </Row>
    )
}

export default GroupSensors

// kate: replace-tabs on; tab-width 4; show-tabs on;
