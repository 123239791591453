import { useCallback, useEffect, useState } from 'react'
import fetchData from '../lib/fetchData'

export default function useLimitations() {
    const [limitations, setLimitations] = useState(null)
    const [loading, setLoading] = useState(false)

    const fetchLimitations = useCallback(() => {
        fetchData({
            url: '/api/v1/limitations',
            setData: setLimitations,
            setLoading,
        })
    }, [])
    useEffect(() => {
        fetchLimitations()
    }, [fetchLimitations])
    return { limitations, loading, fetchLimitations }
}
