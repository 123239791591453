import React from 'react'
import { Spinner } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import SystemsTable from './Systems/Table'
import store from '../../shared/redux/store'

export default function SystemView(props) {
    const systems = store.getState().systems
    const systemsUpdated = store.getState().systemsUpdated

    const columns = [
        [
            {
                Header: <FormattedMessage id="description" defaultMessage="Description" />,
                accessor: 'description',
            },
            {
                Header: <FormattedMessage id="userdescription" defaultMessage="User description" />,
                accessor: 'user_description',
            },
        ],
        [
            {
                Header: <FormattedMessage id="invertors.working" defaultMessage="Working Invertors" />,
                accessor: 'working_invertors_count',
            },
            {
                Header: <FormattedMessage id="invertors.all" defaultMessage="All Invertors" />,
                accessor: 'invertors_count',
            },
        ],
        [
            {
                Header: <FormattedMessage id="powerratio" defaultMessage="Power Ratio" />,
                accessor: 'power_chart',
            },
            {
                Header: <FormattedMessage id="currentpower" defaultMessage="Current Power" />,
                accessor: 'power',
            },
        ],
        {
            Header: <FormattedMessage id="lastupdate" defaultMessage="Last Known Data" />,
            accessor: 'last_know_data',
        },
        {
            Header: <FormattedMessage id="status" defaultMessage="Status" />,
            accessor: 'error',
        },
    ]

    return systems && systems.length > 0 ? (
        <SystemsTable data={systemsUpdated ? systemsUpdated : systems} columns={columns} />
    ) : (
        <Spinner animation="border" role="status" variant="dark">
            <span className="sr-only">Loading...</span>
        </Spinner>
    )
} // SystemView

// kate: replace-tabs on; tab-width 4; show-tabs on;
