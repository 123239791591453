import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import InvertorStatistics from '../../shared/components/InvertorStatistics'
import { useDataStreamSingle } from '../../shared/hooks'
import store from '../../shared/redux/store'
import InvertorPowerGraph from './InvertorPowerGraph'
import SunPosition from './InvertorDetail/SunPosition'
import SunAngle from './InvertorDetail/SunAngle'
const InvertorDetail = (props) => {
    useDataStreamSingle(`/api/v1/invertors/${props.match.params.invertor}`, 'invertor')

    const invertor = store.getState().invertor
    const systems = store.getState().systems
    const [system, setSystem] = useState(false)
    const intl = useIntl()
    useEffect(() => {
        const systemData = invertor && systems && systems.find((item) => item.system === invertor.system)
        setSystem(systemData)
    }, [invertor, systems])
    return invertor ? (
        <div className="invertorDetail pl-md-3">
            <Row className="mb-4">
                <Col xs={12} md={6}>
                    <h1 className="h2">Invertor detail</h1>
                </Col>
                <Col xs={12} md={6} className="text-md-right">
                    {system && (
                        <Button
                            as={Link}
                            to={`/system/${system.system}/detail`}
                            variant="outline-primary"
                            className="mr-3"
                        >
                            <FontAwesomeIcon
                                icon={faAngleDoubleLeft}
                                title={intl.formatMessage({ id: 'backto', defaultMessage: 'Back to' })}
                            />
                            &nbsp;
                            <FormattedMessage id="backto" defaultMessage="Back to" />{' '}
                            <FormattedMessage id="systems.system" defaultMessage="System" />
                        </Button>
                    )}
                </Col>
            </Row>
            <Row>
                <Col lg="4" xl="3">
                    <div className="mb-2 border-bottom pb-2">
                        <h6>
                            <FormattedMessage id="systems.system" defaultMessage="System" />
                        </h6>
                        <b>
                            {system && (
                                <Link to={`/system/${system.system}/detail`}>
                                    {system.description}, {system.user_description}
                                </Link>
                            )}
                        </b>
                    </div>
                    <div className="mb-2 border-bottom pb-2">
                        <h6>
                            <FormattedMessage id="invertors.sn" defaultMessage="Serial number" />
                        </h6>
                        <b>{invertor.sn}</b>
                    </div>
                    <div className="mb-2 border-bottom pb-2">
                        <h6>
                            <FormattedMessage id="name" defaultMessage="Name" />
                        </h6>
                        <b>{invertor.name}</b>
                    </div>
                    <div className="mb-2 border-bottom pb-2">
                        <h6>
                            <FormattedMessage id="statistics" defaultMessage="Statistics withing a group" />
                        </h6>
                        <InvertorStatistics invertor={invertor} />
                    </div>
                    <Row>
                        <Col xs="12" className="mb-2 border-bottom pb-2">
                            <h6>Poloha slunce </h6>
                            <SunPosition invertor={invertor} />
                        </Col>
                        <Col xs="12" className="mb-2 border-bottom pb-2">
                            <h6>Úhel dopadu slunce</h6>
                            <SunAngle invertor={invertor} />
                        </Col>
                    </Row>
                </Col>
                <Col lg="8" xl="9">
                    {system && (
                        <InvertorPowerGraph
                            systemID={system.system}
                            maxPower={system.maxpower}
                            invertorID={props.match.params.invertor}
                        />
                    )}
                </Col>
            </Row>
        </div>
    ) : (
        'loading'
    )
}

export default InvertorDetail
