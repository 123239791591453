import React from 'react'

import { Checkmark, Close } from './Icons'

const SystemStatus = ({ error }) => {
    const style = {
        height: '1.25rem',
        flex: '0 0 1.25rem',
    }
    return error > 0 ? (
        <Close style={{ ...style }} className="text-danger" />
    ) : (
        <Checkmark style={{ ...style }} className="text-success" />
    )
}

export default SystemStatus

// kate: replace-tabs on; tab-width 4; show-tabs on;
