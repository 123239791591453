const data = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_DATA_SUCCESS':
            return { ...state, ...action.payload }
        default:
            return state
    }
}

export default data
