import React, { useEffect, useRef } from 'react'

const draw = (context, data) => {

    const width  = context.canvas.width;
    const height = context.canvas.height;
    const halfHeight = height * 0.5;
    const tickBottom = height * 0.25;
    const tickTop    = height * 0.75;
    const tickLength = tickTop - tickBottom;

    context.fillStyle = '#e9ecef';
    context.fillRect(0, 0, width, height);
    context.fillStyle = '#ff8888';
    context.fillRect(width * (data.min), 0, width * (data.max - data.min), height);
    context.fillStyle = '#88ff88';
    context.fillRect(width * data.q1, 0, width * (data.q3 - data.q1), height);
    context.fillStyle = '#8888ff';
    context.lineWidth = 1;
    context.moveTo(width * data.m, 0);
    context.lineTo(width * data.m, height);
    context.fillStyle = '#000000';
    context.lineWidth = 0.75;
    context.moveTo(width * data.pwr - tickLength / 2, halfHeight + tickLength / 2);
    context.lineTo(width * data.pwr + tickLength / 2, halfHeight - tickLength / 2);
    context.moveTo(width * data.pwr + tickLength / 2, halfHeight + tickLength / 2);
    context.lineTo(width * data.pwr - tickLength / 2, halfHeight - tickLength / 2);
    context.stroke();
}

const InvertorStatistics = ({ invertor }) => {

    const canvasRef = useRef(null);


    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        draw(context, invertor.statistics)
    }, [invertor])

    return (
        <canvas ref={canvasRef} width={160} height={16} key={invertor.invertor} className="invertor-statistics" />
    )
}

export default InvertorStatistics

// kate: replace-tabs on; tab-width 4; show-tabs on;
