import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';

import Clock from './Clock';
import Menu from './Menu';
import UserInfo from './UserInfo';
import LangSwitcher from './LangSwitcher'

export default function Header(props) {

    return (
        <Navbar bg="dark" variant="dark" expand="md" fixed="top" className="p-0 shadow">

            <Navbar.Brand href="/group" className="col-3 col-xl-2 mr-0" title="Back to Groups">
                <img src="/logo.svg" className="logo" alt="Solartec logo" /> Fotomon Control
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="primary-navigation" />

            <Navbar.Collapse id="primary-navigation" className="justify-content-end">
                <Menu />
                <Nav className="ml-auto px-3">
                    <UserInfo />
                    <LangSwitcher lang={props.lang}/>
                    <Clock />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
} // Header()

// kate: replace-tabs on; tab-width 4; show-tabs on;
