import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import store from '../../../shared/redux/store'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import useDataStream from '../../../shared/hooks/useDataStream'
import useFetch from '../../../shared/hooks/useFetch'
HighchartsMore(Highcharts)
const SystemPowerGraph = ({ systemID, maxPower }) => {
    const intl = useIntl()
    const [data, setData] = useState(null)
    // const { data: invertors } = useDataStream({
    //     url: `/api/v1/nninvertor/?system=${systemID}`,
    //     eventUrl: `/api/v1/nninvertor/events/?system=${systemID}`,
    //     identifier: 'group',
    //     type: `nninvertor-${systemID}`,
    // })

    const { data: invertors } = useFetch({
        url: `/api/v1/nninvertor/?system=${systemID}`,
    })

    useEffect(() => {
        const newArray = {
            items: [],
            x: [],
        }

        if (invertors) {
            invertors.forEach((invertor, index) => {
                //if (index > 20 && index < 70) {
                if (invertor.quartile2 > 0) {
                    const q1 = invertor.quartile1
                    const q2 = invertor.quartile2
                    const q3 = invertor.quartile3
                    const iqr = 1.5 * (q3 - q1)
                    const ni = invertor.pcount
                    const ip = ni !== 0 ? invertor.sum_power / ni : 0
                    const lowerfence = q2 - iqr < 0 ? 0 : q2 - iqr
                    const upperfence = Math.min(q2 + iqr, invertor.max)
                    //const time = moment(invertor.time).format('HH:mm')
                    const time = new Date(invertor.time)
                    const item = {
                        x: time.getTime(),
                        low: Math.round((lowerfence * maxPower) / 1000),
                        q1: Math.round((q1 * maxPower) / 1000),
                        median: Math.round((q2 * maxPower) / 1000),
                        q3: Math.round((q3 * maxPower) / 1000),
                        //x: Math.round(ip * maxPower / 1000),
                        high: Math.round((upperfence * maxPower) / 1000),
                    }
                    // console.log(invertor.time, Math.round((ip * maxPower) / 1000))
                    newArray.items.push(item)
                    newArray.x.push([time.getTime(), Math.round((ip * maxPower) / 1000)])
                }
            })
        }
        // console.log(newArray)

        invertors && setData(newArray)
    }, [invertors, maxPower])

    return data ? (
        Object.values(data.items).length > 0 ? (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    yAxis: {
                        title: {
                            text: intl.formatMessage({
                                id: 'systemPowerGraph.yAxis',
                                defaultMessage: 'Power [kW]',
                            }),
                        },
                    },
                    xAxis: {
                        title: {
                            text: intl.formatMessage({ id: 'systemPowerGraph.xAxis', defaultMessage: 'Time [hour]' }),
                        },
                        type: 'datetime',
                    },
                    series: [
                        {
                            name: intl.formatMessage({
                                id: 'systemPowerGraph.invertorGroupTitle',
                                defaultMessage: 'Invertors in system',
                            }),
                            data: data.items,
                            type: 'boxplot',
                        },
                    ],
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false,
                            },
                        },
                    },
                    tooltip: {
                        valueSuffix:
                            ' ' + intl.formatMessage({ id: 'systemPowerGraph.tooltipUnit', defaultMessage: 'kW' }),
                        valueDecimals: 0,
                        xDateFormat: '%d.%m.%Y %H:%M',
                        shared: true,
                    },
                    title: {
                        text: intl.formatMessage({
                            id: 'systemPowerGraph.title',
                            defaultMessage: 'Today system power',
                        }),
                    },
                    credits: {
                        enabled: false,
                    },
                }}
            />
        ) : (
            'no usable data'
        )
    ) : (
        'loading'
    )
}

export default SystemPowerGraph

// kate: replace-tabs on; tab-width 4; show-tabs on;
