import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

export default function Menu(props) {
    const location = useLocation()

    return (
        <Nav activeKey={location.pathname} className="mr-auto px-3">
            <Nav.Link as={Link} to="/system">
                <FormattedMessage id="systems.systems" defaultMessage="Systems" />
            </Nav.Link>
            <Nav.Link as={Link} to="/group">
                <FormattedMessage id="groups.groups" defaultMessage="Groups" />
            </Nav.Link>
            <Nav.Link as={Link} to="/limitations">
                <FormattedMessage id="limitations" defaultMessage="Limitations" />
            </Nav.Link>
        </Nav>
    )
} // Menu()

// kate: replace-tabs on; tab-width 4; show-tabs on;
