import React from 'react'
import moment from 'moment'
import 'moment-timezone'

const DateTime = ({ data, inline, dateFormat, timeFormat }) => {
    const dateFormatVal = dateFormat !== undefined ? dateFormat : 'l'
    const timeFormatVal = timeFormat !== undefined ? timeFormat : 'LT'

    const date = data !== null ? moment(data).tz(moment.tz.guess()).format(dateFormatVal) : ''
    const time = data !== null ? moment(data).tz(moment.tz.guess()).format(timeFormatVal) : ''

    const dateTime = (
        <div className={inline ? 'd-flex' : ''}>
            <div className="d-flex align-items-right">
                <span>{date}</span>
            </div>
            <div className={`d-flex align-items-left ${inline ? 'ml-3' : ''}`}>
                <span>{time}</span>
            </div>
        </div>
    )

    return dateTime
}

export default DateTime

// kate: replace-tabs on; tab-width 4; show-tabs on;
