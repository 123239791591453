import React from 'react'
import { ProgressBar } from 'react-bootstrap'

const PowerRatio = ({ power, maxpower, bgColor }) => {
    const powerRatio = power > 0 && Math.round(power / (maxpower / 100))
    return (
        <ProgressBar now={powerRatio} label={<span>{powerRatio}%</span>} variant={`${powerRatio < 25 ? 'danger' : powerRatio < 50 ? 'warning' : powerRatio < 75 ? 'success' : 'primary'}`} className={`bg-${bgColor} ${powerRatio < 45 && powerRatio > 0 ? 'center-label': ''}`}/>
    )
}

export default PowerRatio

// kate: replace-tabs on; tab-width 4; show-tabs on;
