import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container, Row, Button } from 'react-bootstrap'

import routes from '../routes'
import NotFoundView from '../../modules/error/NotFoundView'

import Sidebar from './Sidebar'
import Breadcrumbs from './Breadcrumbs'
import { Arrow } from './Icons'

export default function Content(props) {
    const [collapseState, setCollapseState] = React.useState(false)
    return (
        <Container fluid id="content">
            <Row>
                <Sidebar collapseState={collapseState} />
                <Switch>
                    {routes.map(({ path, name, Component }, key) => (
                        <Route
                            exact
                            path={path}
                            key={key}
                            render={(props) => {
                                const crumbs = routes
                                    .filter(({ path }) => props.match.path.includes(path))
                                    .map(({ path, ...rest }) => ({
                                        path: Object.keys(props.match.params).length
                                            ? Object.keys(props.match.params).reduce(
                                                  (path, param) => path.replace(`:${param}`, props.match.params[param]),
                                                  path
                                              ) // reduce()
                                            : path,
                                        ...rest,
                                    }))
                                /*
                                console.log(`Generated crumbs for ${props.match.path}`);
                                crumbs.map(({ name, path }) => console.log({ name, path }));
*/
                                return (
                                    <main
                                        role="main"
                                        className={`position-relative ml-sm-auto col-md-${
                                            collapseState ? 12 : 9
                                        } col-lg-${collapseState ? 12 : 10}`}
                                        id="main"
                                    >
                                        <Breadcrumbs crumbs={crumbs} />
                                        <Button
                                            onClick={() => setCollapseState(!collapseState)}
                                            className={`d-flex sidebar-toggler ${
                                                collapseState ? 'sidebar-toggler--hide rounded-left' : 'rounded-right'
                                            }`}
                                            variant="secondary"
                                        >
                                            <Arrow direction="left" className="text-light" />
                                        </Button>
                                        <Component {...props} collapseState={collapseState} className="pt-2" />
                                    </main>
                                )
                            }}
                        />
                    ))}
                    <Redirect extact from="/" to="/group" />
                    <Route component={NotFoundView} />
                </Switch>
            </Row>
        </Container>
    )
} // Content()

// kate: replace-tabs on; tab-width 4; show-tabs on;
