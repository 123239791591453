import React from 'react'
import { useFetch } from '../../shared/hooks'
import { Row, Spinner, Card, Tab, Nav, Badge, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { TransitionGroup } from 'react-transition-group'

import GroupSensors from './Sensors'
import GroupSystem from './System'
import store from '../../shared/redux/store'

const GroupsGroup = ({ group, groupSystemArray, collapseState }) => {
    const identity = JSON.parse(window.localStorage.getItem('identity'))

    const sensors = store.getState().sensors
    const groupSensorsArray =
        sensors && sensors.filter((sensor) => group.group === sensor.system_group && sensor.shown_on_web)

    const [invertors] = useFetch(
        '/api/v1/invertors?group=' + group.group + '&user=' + identity.login + '&password=' + identity.password
    )

    const systemsUpdated = store.getState().systemsUpdated
    return (
        <Card className="mb-4">
            <Card.Header>
                <h5>
                    <FormattedMessage id="groups.group" defaultMessage="Group" /> {group.description}
                </h5>
            </Card.Header>
            <Card.Body>
                <Tab.Container defaultActiveKey="systems">
                    <Nav variant="tabs" className="m-0 border-bottom">
                        <Nav.Item>
                            <Nav.Link eventKey="systems">
                                <FormattedMessage id="systems.systems" defaultMessage="Systems" />{' '}
                                <Badge variant="secondary">{groupSystemArray && groupSystemArray.length}</Badge>{' '}
                            </Nav.Link>
                        </Nav.Item>
                        {groupSensorsArray && groupSensorsArray.length > 0 && (
                            <Nav.Item>
                                <Nav.Link eventKey="sensors">
                                    <FormattedMessage id="sensors.sensors" defaultMessage="Sensors" />{' '}
                                    <Badge variant="secondary">{groupSensorsArray && groupSensorsArray.length}</Badge>
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {invertors && (
                            <Nav.Item>
                                <Nav.Link eventKey="invertors">
                                    <FormattedMessage id="invertors.invertors" defaultMessage="Invertors" />{' '}
                                    <Badge variant="secondary">{invertors && invertors.length}</Badge>
                                </Nav.Link>
                            </Nav.Item>
                        )}
                    </Nav>
                    <Tab.Content className="mt-3">
                        <Tab.Pane eventKey="systems">
                            {groupSystemArray && groupSystemArray.length > 0 && (
                                <Row as={TransitionGroup}>
                                    {systemsUpdated &&
                                        systemsUpdated.map((system) => {
                                            return (
                                                group.group === system.group && (
                                                    <GroupSystem
                                                        key={system.system}
                                                        system={system}
                                                        collapseState={collapseState}
                                                    />
                                                )
                                            )
                                        })}
                                </Row>
                            )}
                        </Tab.Pane>
                        {groupSensorsArray && groupSensorsArray.length > 0 && (
                            <Tab.Pane eventKey="sensors">
                                <GroupSensors sensors={groupSensorsArray} />
                            </Tab.Pane>
                        )}
                        {invertors && (
                            <Tab.Pane eventKey="invertors">
                                <Row>
                                    {invertors.map((invertor) => (
                                        <Col key={invertor.invertor} xs="6" md="4" lg="3" xl="2" className="mb-2">
                                            <div className="border rounded h-100 d-flex flex-column justify-content-center px-2 py-1">
                                                <Row>
                                                    <Col xs="12" className="d-flex align-items-center">
                                                        <small>
                                                            <b>{invertor.name}</b>
                                                        </small>
                                                    </Col>
                                                    <Col xs="12" className="d-flex align-items-center">
                                                        <p>Power {invertor.statistics.pwr}</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Tab.Pane>
                        )}
                    </Tab.Content>
                </Tab.Container>
                {systemsUpdated && Object.values(systemsUpdated).length === 0 && (
                    <Spinner animation="border" role="status" variant="info">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                )}
            </Card.Body>
        </Card>
    )
}

export default GroupsGroup

// kate: replace-tabs on; tab-width 4; show-tabs on;
