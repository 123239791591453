import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import NumberFormat from 'react-number-format'
import { Col, Row } from 'react-bootstrap'

import Date from '../../../shared/components/Date'
import PowerValue from '../../../shared/components/PowerValue'
import ProductionValue from '../../../shared/components/ProductionValue'
import SystemPowerGraph from './SystemPowerGraph'
import store from '../../../shared/redux/store'
import WeatherBlock from '../../../shared/components/WeatherBlock'

const Info = ({ systemID, maxPower, system, weather }) => {
    const [groupName, setGroupName] = useState(false)

    const groups = store.getState().groups
    React.useEffect(() => {
        if (groups && system) {
            groups.forEach((group) => system.group === group.group && setGroupName(group.description))
        }
    }, [groups, system])
    return (
        <Row className="py-3">
            <Col xs={12} lg={3} className="col-xxl-3">
                {weather && weather.forecasts.length > 0 && (
                    <div>
                        <h6>
                            <FormattedMessage id="weather-forecast" defaultMessage="Weather forecast" />
                        </h6>

                        {weather.forecasts.map((forecast, index) => (
                            <WeatherBlock
                                weather={forecast}
                                key={index}
                                title={
                                    index > 0 ? (
                                        <FormattedMessage
                                            id="weather-forecast.overmorrow"
                                            defaultMessage="Overmorrow"
                                        />
                                    ) : (
                                        <FormattedMessage id="weather-forecast.tomorrow" defaultMessage="Tomorrow" />
                                    )
                                }
                            />
                        ))}
                    </div>
                )}
                <div className="mb-2 border-bottom pb-2">
                    <h6>
                        <FormattedMessage id="groups.group" defaultMessage="Group" />
                    </h6>
                    <b>{groupName && groupName}</b>
                </div>

                <div className="mb-2 border-bottom pb-2">
                    <h6>
                        <FormattedMessage id="description" defaultMessage="Description" />
                    </h6>
                    <b>{system.user_description}</b>
                </div>

                <div className="mb-2 border-bottom pb-2">
                    <h6>
                        <FormattedMessage id="maxpower" defaultMessage="Maximum power" />
                    </h6>
                    <b>
                        <PowerValue value={system.maxpower} />
                    </b>
                </div>

                <div className="mb-2 border-bottom pb-2">
                    <h6>
                        <FormattedMessage id="maxproduction" defaultMessage="Maximum production" />
                    </h6>
                    <b>
                        <ProductionValue value={system.maxproduction} />
                    </b>
                </div>

                <div className="mb-2 border-bottom pb-2">
                    <h6>
                        <FormattedMessage id="pvarea" defaultMessage="PV area" />
                    </h6>
                    <b>
                        <NumberFormat
                            value={system.pv_area}
                            thousandSeparator={' '}
                            decimalSeparator={','}
                            decimalScale={1}
                            displayType={'text'}
                        />
                        &nbsp;m<sup>2</sup>
                    </b>
                </div>

                <div className="mb-2 border-bottom pb-2">
                    <h6>
                        <FormattedMessage id="operationdate" defaultMessage="Operation date" />
                    </h6>
                    <b>
                        <Date data={system.operation_date} inline />
                    </b>
                </div>

                <div>
                    <h6>
                        <FormattedMessage id="probability" defaultMessage="Priorita" />
                    </h6>
                    <b>
                        <NumberFormat
                            value={system.probability}
                            thousandSeparator={' '}
                            decimalSeparator={','}
                            decimalScale={3}
                            displayType={'text'}
                        />
                    </b>
                </div>
            </Col>
            <Col xs={12} lg={9}>
                <SystemPowerGraph systemID={systemID} maxPower={maxPower} />
            </Col>
        </Row>
    )
}

export default Info

// kate: replace-tabs on; tab-width 4; show-tabs on;
